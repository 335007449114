body {
  background-color: #fdf9f5;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 130px);
  justify-content: center;
  font-weight: 300;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
}

.App-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  height: 50px;
}

.page {
  max-width: 500px;
  padding: 20px;
  padding-bottom: 50px;
}

.contacts {
  max-width: 600px;
  width: 100%;
}

.link,
button {
  text-transform: uppercase;
  font-size: calc(0.7rem + 0.128 * ((100vw - 40rem) / 80));
  letter-spacing: 0.1rem;
  list-style: none;
}

.link li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

ul {
  text-align: left;
  margin: 0;
}

.navLink {
  all: inherit;
}

.link :hover,
.link .active,
.standardA {
  color: #0b789e;
}

.divider,
h5 {
  color: #076750;
}

h2 {
  color: #7f0925;
}

label {
  padding-right: 0.5rem;
}

input,
textarea {
  width: 100%;
  box-sizing: border-box;
}

input,
textarea {
  border: 1px solid lightgrey;
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
}

textarea {
  width: 300px;
  max-width: 80vw;
}

.form-group {
  margin-bottom: 10px;
}

.standardA {
  text-decoration: none;
}

h2,
h5 {
  font-weight: 300;
  letter-spacing: 0.2rem;
  line-height: 1.5em;
  text-decoration: none;
}

h2 {
  font-size: 1.3rem;
}

p,
ul,
input,
textarea,
button {
  font-weight: 300;
  line-height: 1.8em;
  text-decoration: none;
  letter-spacing: 0.1rem;
  padding: 10px;
  text-align: justify;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  text-align: center;
  background-color: #0b789e;
  color: white;
  font-weight: 600;
}

p,
input,
textarea {
  font-size: 0.7rem;
}

ul {
  font-size: 0.8rem;
  padding-left: 15px;
}

.left {
  text-align: left;
}

.center,
.divider {
  text-align: center;
}

.center-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}
